import rootReducer from "./reducer";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore(
  {
    reducer: rootReducer,
    preloadedState: loadFromLocalStorage(),
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

function saveToLocalStorage(state) {
  try {
    const shopState = JSON.stringify(state);
    localStorage.setItem("cart", shopState);
  } catch (e) {
    console.warn(e);
  }
}

function loadFromLocalStorage() {
  try {
    const shopState = localStorage.getItem("cart");
    if (shopState === null) return undefined;
    const favoushopData = JSON.parse(shopState);
    return {
      shop: {
        products: favoushopData,
      },
    };
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

store.subscribe(() => saveToLocalStorage(store.getState().shop.products));

export default store;
