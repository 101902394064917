import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../api";

export const consultationPost = createAsyncThunk(
  "contact-form/consultationPost",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/consultation-form`, data);
      return response;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

export const individualPost = createAsyncThunk(
  "contact-form/individualPost",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/individual-form`, data);
      return response;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

const ContactFormSlice = createSlice({
  name: "contact-form",
  initialState: {
    consultation: {
      loading: false,
      message: null,
      status: null,
    },
    loading: true,
    message: null,
    status: null,
  },
  reducers: {
    cleanDataLoadingConsultation: (state) => {
      state.consultation.loading = false;
      state.consultation.message = null;
      state.consultation.status = null;
    },
    cleanDataLoading: (state) => {
      state.loading = false;
      state.message = null;
      state.status = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(consultationPost.fulfilled, (state, actions) => {
      state.consultation.loading = false;
      state.consultation.message = actions.payload.data.message;
      state.consultation.status = actions.payload.data.status;
    });
    builder.addCase(consultationPost.pending, (state, actions) => {
      state.consultation.loading = true;
    });
    builder.addCase(consultationPost.rejected, (state, actions) => {
      state.consultation.loading = false;
      state.consultation.message = actions.payload.data.message;
      state.consultation.status = actions.payload.data.status;
    });
    builder.addCase(individualPost.fulfilled, (state, actions) => {
      state.loading = false;
      state.message = actions.payload.data.message;
      state.status = actions.payload.data.status;
    });
    builder.addCase(individualPost.pending, (state, actions) => {
      state.loading = true;
    });
    builder.addCase(individualPost.rejected, (state, actions) => {
      state.loading = false;
      state.message = actions.payload.data.message;
      state.status = actions.payload.data.status;
    });
  },
});

export const { cleanDataLoading, cleanDataLoadingConsultation } =
  ContactFormSlice.actions;
export default ContactFormSlice.reducer;
