import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../api";

export const SettingGet = createAsyncThunk("setting/SettingGet", async () => {
  const response = await axios({
    method: "get",
    url: `/api/setting`,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
});

const SettingSlice = createSlice({
  name: "setting",
  initialState: {
    data: {},
    loading: false,
  },
  reducers: {
    // AddData: (state) => {
    //   state.data = [];
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(SettingGet.fulfilled, (state, actions) => {
      state.data = Object.assign(...actions.payload);
    });
    builder.addCase(SettingGet.pending, (state, actions) => {});
    builder.addCase(SettingGet.rejected, (state, actions) => {});
  },
});

// export const { AddData } = ProductsSlice.actions;
export default SettingSlice.reducer;
