import { createSlice } from "@reduxjs/toolkit";

const ModalSettingSlice = createSlice({
  name: "modal-settings",
  initialState: {
    open: false,
    modal_name: false,
  },
  reducers: {
    openModal: (state, payload) => {
      state.open = true;
      state.modal_name = payload.payload;
    },
    closeModal: (state) => {
      state.open = false;
      state.modal_name = false;
    },
  },
});

export const { openModal, closeModal } = ModalSettingSlice.actions;
export default ModalSettingSlice.reducer;
