import { combineReducers } from "redux";
import ContactFormSlice from "../services/ContactFormSlice";
import ModalSettingSlice from "../services/ModalSettingSlice";
import PaymentsSlice from "../services/PaymentsSlice";
import ProductsSlice from "../services/ProductsSlice";
import SettingSlice from "../services/SettingSlice";
import ShoppingCartSlice from "../services/ShoppingCartSlice";

const rootReducer = combineReducers({
  shop: ShoppingCartSlice,
  payment: PaymentsSlice,
  products: ProductsSlice,
  setting: SettingSlice,
  contactForm: ContactFormSlice,
  modal: ModalSettingSlice,
});

export default rootReducer;
