import axios from "axios";

export default axios.create({
  withCredentials: false,
  baseURL: `https://server-git-main-ozornyshka.vercel.app/`,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});
