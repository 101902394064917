import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../api";

export const ProductsFetch = createAsyncThunk(
  "products/ProductsFetch",
  async () => {
    const response = await axios({
      method: "get",
      url: `/api/products`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

export const ProductFind = createAsyncThunk(
  "products/ProductFind",
  async (e) => {
    const response = await axios({
      method: "get",
      url: `/api/product/${e}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
);

const ProductsSlice = createSlice({
  name: "products",
  initialState: {
    data: [],
    loading: false,
  },
  reducers: {
    // AddData: (state) => {
    //   state.data = [];
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(ProductsFetch.fulfilled, (state, actions) => {
      state.data = actions.payload;
    });
    builder.addCase(ProductsFetch.pending, (state, actions) => {});
    builder.addCase(ProductsFetch.rejected, (state, actions) => {});
  },
});

// export const { AddData } = ProductsSlice.actions;
export default ProductsSlice.reducer;
