import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// export const checkerLinks = createAsyncThunk();

const ShoppingCartSlice = createSlice({
  name: "shop",
  initialState: {
    products: [],
    oneClickShop: [],
    total: null,
    loading: false,
  },
  reducers: {
    AddData: (state, actions) => {
      const {
        _id: id,
        title,
        sku,
        img,
        price,
        calculated,
        url,
      } = actions.payload;
      let isShop = false;
      state.products.forEach((el) => {
        if (el._id === id) {
          el.counter++;
          isShop = true;
        }
      });
      if (!isShop)
        state.products.push({
          title: title,
          _id: id,
          sku: sku,
          img: img,
          calculated: calculated,
          price: price,
          counter: 1,
          url: url,
        });
    },
    RemoveItemData: (state, actions) => {
      state.products.forEach((el, i, arr) => {
        if (el._id === actions.payload._id) {
          arr.splice(arr.indexOf(el), 1);
        }
      });
    },
    DecrementItemData: (state, actions) => {
      state.products.forEach((el, i, arr) => {
        if (el.counter === 0) {
          return;
        }
        if (el._id === actions.payload._id) {
          el.counter--;
        }
      });
    },
    TotalShop: (state) => {
      state.total = null;
      state.products.forEach(
        (e) =>
          (state.total =
            state.total + e.counter * e.price.replace(/[^0-9.]/gim, ""))
      );
    },
    DeleteShop: (state) => {
      state.products = [];
    },
    OneClickShopAdd: (state, actions) => {
      state.oneClickShop = [];
      state.oneClickShop.push(actions.payload);
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(checkerLinks.fulfilled, (state, actions) => {});
    // builder.addCase(checkerLinks.pending, (state, actions) => {});
    // builder.addCase(checkerLinks.rejected, (state, actions) => {});
  },
});

export const {
  AddData,
  RemoveItemData,
  TotalShop,
  DecrementItemData,
  DeleteShop,
  OneClickShopAdd,
} = ShoppingCartSlice.actions;
export default ShoppingCartSlice.reducer;
