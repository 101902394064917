import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../api";

export const PaymentPost = createAsyncThunk(
  "payment/PaymentPost",
  async (e) => {
    // const body = {
    //   merchantAuthType: "SimpleSignature",
    //   merchantDomainName: "www.market.ua",
    //   orderReference: "DH1656946755",
    //   orderDate: "1415379863",
    //   amount: "1547.36",
    //   currency: "UAH",
    //   orderTimeout: "49000",
    //   productName: [
    //     "Процессор Intel Core i5-4670 3.4GHz",
    //     "Память Kingston DDR3-1600 4096MB PC3-12800",
    //   ],
    //   productPrice: ["1000", "547.36"],
    //   productCount: ["1", "1"],
    //   defaultPaymentSystem: "card",
    // };
    const respons = await axios({
      method: "post",
      url: `/api/pay`,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      data: e,
    }).then((dataRes) =>
      dataRes.status === 200
        ? axios({
            method: "post",
            url: `https://secure.wayforpay.com/pay?behavior=offline`,
            headers: {
              "Content-Type": "multipart/form-data;",
            },
            data: dataRes.data,
          }).then((data) => data)
        : null
    );

    return respons.data;
    // const generedUrl = await axios({
    //   method: "post",
    //   url: `https://secure.wayforpay.com/pay?behavior=offline`,
    //   headers: {
    //     "Content-Type": "multipart/form-data;",
    //   },
    //   data: response.data,
    // });
    // return console.log(generedUrl);
  }
);

export const CreateOrder = createAsyncThunk(
  "payment/CreateOrder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/order`, data);
      return response;
    } catch (error) {
      rejectWithValue(error.response);
    }
  }
);

const PaymentsSlice = createSlice({
  name: "payment",
  initialState: {
    data: [],
    loading: false,
    url: "",
  },
  reducers: {
    // AddData: (state) => {
    //   state.data = [];
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(PaymentPost.fulfilled, (state, actions) => {
      state.url = actions.payload?.url;
    });
    builder.addCase(PaymentPost.pending, (state, actions) => {});
    builder.addCase(PaymentPost.rejected, (state, actions) => {});
  },
});

// export const { AddData } = ProductsSlice.actions;
export default PaymentsSlice.reducer;
