import React, { Suspense, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProductsFetch } from "./services/ProductsSlice";
import { Routes, Route, Navigate } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import { SettingGet } from "./services/SettingSlice";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { openModal } from "./services/ModalSettingSlice";
import AOS from "aos";
import "aos/dist/aos.css";

const Home = React.lazy(() => import("./page/Home"));
const Card = React.lazy(() => import("./page/Card"));
const ThankYou = React.lazy(() => import("./page/ThankYou"));

const override = {
  display: "block",
  position: "fixed",
  top: "50%",
  left: "50%",
  margin: "-75px 0 0 -75px",
  borderColor: "#c8b273",
};

function App() {
  const dispatch = useDispatch();
  const setting = useSelector((state) => state.setting.data);

  useEffect(() => {
    dispatch(ProductsFetch());
    dispatch(SettingGet());
  }, [dispatch]);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <HelmetProvider>
      <Helmet
        title={setting?.seo?.title}
        meta={[
          setting?.seo?.description
            ? {
                name: "description",
                content: setting?.seo?.description,
              }
            : {},
          setting?.seo?.keywords
            ? {
                name: "keywords",
                content: setting?.seo?.keywords,
              }
            : {},

          {
            property: "og:url",
            content: `${window.location.origin}`,
          },
          setting?.seo?.title
            ? {
                name: "og:title",
                content: setting?.seo?.title,
              }
            : {},
          setting?.seo?.description
            ? {
                name: "og:description",
                content: setting?.seo?.description,
              }
            : {},
          {
            property: "og:type",
            content: "website",
          },
          {
            property: "og:image",
            href: "/assets/logo.png",
          },
        ]}
        link={[
          {
            rel: "canonical",
            href: `${window.location.href}`,
          },
          {
            rel: "shortcut icon",
            href: "/assets/logo_transparent.ico",
            type: "image/x-icon",
          },
        ]}
      ></Helmet>
      <div className="App">
        <Suspense
          fallback={
            <HashLoader color="#c8b273" cssOverride={override} size={150} />
          }
        >
          <Routes>
            <Route path="/" element={<App />} />
            <Route index element={<Home />} />
            <Route path="/product">
              <Route path=":productId" element={<Card />}>
                <Route index element={<Navigate to={-1} replace />} />
              </Route>
              <Route index element={<Navigate to={-1} replace />} />
            </Route>

            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                </main>
              }
            />
            <Route path="/thank-you" element={<ThankYou />} />
          </Routes>
        </Suspense>
      </div>
    </HelmetProvider>
  );
}

export default App;
